<template>
	<el-drawer
		title="查看信息"
		:visible.sync="drawer_"
		destroy-on-close
		direction="rtl"
		custom-class="demo-drawer"
		ref="drawer"
		size="40%"
		@open="open"
	>
		<div class="demo-drawer__content" style="width: 100%">
			<!-- 抽屉 -->
			<div class="edit" style="width: 100%">
				<el-form
					ref="ruleForm"
					style="width: 90%"
					class="demo-ruleForm"
					label-position="rigth"
					label-width="150px"
				>
					<el-form-item label="发送人：">
						<div>{{ dataObj.senderName }}</div>
					</el-form-item>
					<el-form-item label="发时间：">
						<div>{{ dataObj.sendTime }}</div>
					</el-form-item>
					<el-form-item label="收件人：">
						<div>{{ dataObj.reciverName }}</div>
					</el-form-item>
					<el-form-item label="消息标题：">
						<div>{{ dataObj.title }}</div>
					</el-form-item>
					<el-form-item label="消息内容：">
						<div>{{ dataObj.content }}</div>
					</el-form-item>
					<el-form-item label="消息类型：">
						<div>{{ dataObj.messageTypeName }}</div>
					</el-form-item>
				</el-form>
				<div class="demo-drawer__footer">
					<el-button @click="$emit('close')">关 闭</el-button>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
export default {
	name: 'examineDrawer',
	props: {
		examineVisible: {
			type: Boolean,
			required: true,
		},
		dataObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {};
	},
	methods: {
		open() {},
	},
	computed: {
		drawer_: {
			get() {
				return this.examineVisible;
			},
			set(v) {
				this.$emit('update:examineVisible', v);
			},
		},
	},
};
</script>
<style lang="scss" scoped>
.mb {
	margin-bottom: 0px;
}
.box-card {
	margin-bottom: 10px;
}
.demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 30px 0;
}
.demo-drawer__footer button {
	width: 20% !important;
	margin: 0 10px;
}
.el-date-editor {
	width: 100%;
}

.edit {
	p {
		font-weight: 800;
		margin-bottom: 20px;
	}
	span {
		text-indent: 40px;
		display: block;
		margin-bottom: 10px;
		font-weight: 800;
	}
}
</style>
