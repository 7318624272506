<template>
	<div class="notification">
		<div class="my-title">
			<p>通知管理</p>
		</div>
		<div style="margin: 0 20px">
			<div style="padding: 0 10px 10px 0">
				<div>
					<span>发送人:</span>
					<el-input
						style="width: 15%; margin: 0 10px"
						placeholder="请输入发送人"
						v-model="params.senderName"
					></el-input>
					<span>消息标题:</span>
					<el-input
						style="width: 15%; margin: 0 10px"
						placeholder="请输入消息标题"
						v-model="params.title"
					></el-input>
					<span>消息状态:</span>
					<el-select
						style="width: 15%; margin: 0 10px"
						v-model="params.messageStatus"
						placeholder="请选择"
					>
						<el-option
							v-for="item in MessageStatus"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						>
						</el-option>
					</el-select>
					<el-button
						type="primary"
						@click="
							() => {
								params.pageNo = 1;
								getGystemMessageList();
							}
						"
						>查询</el-button
					>

					<el-button
						type="primary"
						@click="
							() => {
								params.messageStatus = '';
								params.senderName = '';
								params.title = '';
								params.pageNo = 1;
								getGystemMessageList();
							}
						"
						>清空</el-button
					>
				</div>
			</div>
			<!-- table 列表 -->
			<el-table
				height="calc(100vh - 290px)"
				:data="tableData"
				border
				style="width: 100%"
			>
				<el-table-column
					width="100px"
					align="left"
					prop="senderName"
					label="发送人"
				>
				</el-table-column>
				<el-table-column
					width="170px"
					align="left"
					prop="sendTime"
					label="发送时间"
				></el-table-column>
				<el-table-column
					width="150px"
					align="left"
					prop="title"
					label="消息标题"
				></el-table-column>
				<el-table-column
					align="left"
					prop="content"
					label="消息内容"
				></el-table-column>
				<el-table-column
					width="80"
					align="left"
					prop="messageStatusName"
					label="状态"
				>
				</el-table-column>
				<el-table-column
					width="120"
					fixed="right"
					align="center"
					label="操作"
				>
					<template slot-scope="scope">
						<p
							@click="handleEdit(scope.$index, scope.row)"
							style="
								color: rgb(2, 167, 240);
								cursor: pointer;
								margin-right: 17px;
							"
						>
							查看详情
						</p>
					</template>
				</el-table-column>
				<el-empty
					slot="empty"
					description="没有找到符合条件的数据"
				></el-empty>
			</el-table>
			<!-- 分页 -->
			<div class="fy">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="params.pageNo"
					:page-sizes="[5, 10, 20, 30, 50]"
					:page-size="10"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total"
				>
				</el-pagination>
			</div>
			<examineDrawer
				@close="examineVisible = false"
				:dataObj="dataObj"
				:examineVisible.sync="examineVisible"
			/>
			<publicInfoDrawer
				:CompliesCreditProductPolicy="CompliesCreditProductPolicy"
				:InterestedProvidingFinanceSolutions="
					InterestedProvidingFinanceSolutions
				"
				:privacyType="privacyType"
				:PresidentCooperateWillingness="PresidentCooperateWillingness"
				:allData="allData"
				:dialogVisible.sync="publicDialogVisible"
				:paramsId="paramsId"
				@closeVisible="publicDialogVisible = false"
			/>

			<publicReply
				:paramsId="publicReplyObj"
				:replyVisible.sync="replyVisible"
			/>
			<feedback
				@closeVisibleReply="
					() => {
						feedbackVisible = false;
						replyVisible = true;
					}
				"
				:paramsId="publicReplyObj"
				:feedbackVisible.sync="feedbackVisible"
				@closeVisible="feedbackVisible = false"
				@openPublicInfoDrawer="openPublicInfoDrawer"
			/>
			<inquiryDrawer
				:inquiryVisible="inquiryVisible"
				:paramsId="publicReplyObj"
				@closeVisible="inquiryVisible = false"
				@isShowDialogVisible="isShowDialogVisible"
			/>
			<reauthentication
				:paramsId="publicReplyObj"
				:reautVisible.sync="reautVisible"
				@close="reautVisible = false"
			/>
		</div>
	</div>
</template>

<script>
import examineDrawer from './module/examineDrawer.vue';
import inquiryDrawer from './module/inquiryDrawer.vue';
import reauthentication from './module/reauthentication.vue';
import feedback from './module/feedback.vue';
import { myMixin } from '@/mixins';

import {
	systemMessageList,
	itemByType,
	readSystemMessage,
	getClientInfoSensitive,
} from '@/api/index.js';

export default {
	name: 'notification',
	mixins: [myMixin],
	data() {
		return {
			CompliesCreditProductPolicy: [],
			InterestedProvidingFinanceSolutions: [],
			params: {
				messageStatus: '',
				senderName: '',
				pageSize: 10,
				pageNo: 1,
				title: '',
			},
			MessageStatus: [],
			tableData: [],
			total: 0,
			examineVisible: false,
			dataObj: {},
			replyVisible: false,
			feedbackVisible: false,
			inquiryVisible: false,
			reautVisible: false,
			publicReplyObj: {
				businessType: 8,
				clientPresidentRelatedId: '',
			},
		};
	},
	components: { examineDrawer, feedback, inquiryDrawer, reauthentication },
	created() {
		this.getItemByType('PresidentCooperateWillingness');

		this.getItemByType('CompliesCreditProductPolicy');
		this.getItemByType('InterestedProvidingFinanceSolutions');
		this.getItemByType('MessageStatus');
		this.getGystemMessageList();
	},
	methods: {
		isShowDialogVisible(item) {
			if (item.clientId) {
				this.showDialogVisible(item, true, true);
			}
		},
		async openPublicInfoDrawer(data) {
			const res = await getClientInfoSensitive({
				cooperationPresidentId:
					this.$store.state.userInfo.userVo.cooperationPresidentId,
				clientId: data.clientId,
				clientPresidentRelatedId: data.clientPresidentRelatedId,
			});
			this.showDialogVisible(data, res == 1 ? true : false);
		},
		async handleEdit(index, row) {
			this.getReadSystemMessage(row);

			if (row.refType == 18) {
				const res = await getClientInfoSensitive({
					cooperationPresidentId:
						this.$store.state.userInfo.userVo
							.cooperationPresidentId,
					clientId: row.refId,
					clientPresidentRelatedId: row.clientPresidentRelatedId,
				});
				this.showDialogVisible(row, res == 1 ? true : false);
				return false;
			}
			if (row.refType == 14) {
				this.publicReplyObj.clientPresidentRelatedId = row.refId;
				this.publicReplyObj.businessType = 8;
				this.replyVisible = true;
				return false;
			}
			if (row.refType == 15) {
				this.publicReplyObj.clientPresidentRelatedId = row.refId;
				this.publicReplyObj.businessType = 9;
				this.replyVisible = true;
				return false;
			}
			if (row.refType == 16) {
				this.publicReplyObj.clientPresidentRelatedId = row.refId;
				this.publicReplyObj.businessType = 10;
				this.replyVisible = true;
				return false;
			}
			if (row.refType == 20) {
				this.publicReplyObj.clientPresidentRelatedId = row.refId;
				this.publicReplyObj.refType = row.refType;
				this.publicReplyObj.businessType = 8;
				this.feedbackVisible = true;
				return false;
			}
			if (row.refType == 21) {
				this.publicReplyObj = row;
				this.inquiryVisible = true;
				return false;
			}
			if (row.refType == 24) {
				this.publicReplyObj = row;
				this.reautVisible = true;
				return false;
			}
			this.examineVisible = true;
			this.dataObj = row;
		},
		handleSizeChange(val) {
			this.params.pageSize = val;
			this.getGystemMessageList();
		},
		handleCurrentChange(val) {
			this.params.pageNo = val;
			this.getGystemMessageList();
		},
		async getGystemMessageList() {
			const res = await systemMessageList(this.params);
			this.tableData = res.list;
			this.total = res.totalRows;
		},
		async getReadSystemMessage(data) {
			await readSystemMessage({ messageId: data.messageId });
			this.getGystemMessageList();
			this.$store.dispatch('getsystemMessageNumber');
		},
		async getItemByType(name) {
			const res = await itemByType({
				dictionaryType: name,
			});
			this[name] = res.data.map((item) => ({
				value: item.itemValue,
				label: item.itemName,
			}));
		},
	},
};
</script>
<style lang="scss" scoped>
.notification {
	width: 100%;
	height: calc(100vh - 150px);
}
.fy {
	text-align: end;
	padding: 10px 20px;
}
</style>
